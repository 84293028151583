@import styles/helpers

.section
    text-align: center
    padding-bottom: 15px

.container
    padding: 0 40px
    +m
        padding: 0 10px

.wrapper
    width: 100%

.stage
    +hairline-1
    margin-left: 10px
    margin-bottom: 5px
    text-align: left
    color: $neutrals4
    +dark
        color: $neutrals7
    +m
        +hairline-2   

.dots
    li
        margin: 0 !important
        padding: 2px !important
        background-color: $neutrals5
        +dark
            background-color: $neutrals2

.content
    padding: 5px
    border-radius: 10px
    height: 250px
    text-align: left !important
    cursor: pointer
    +m
        height: 120px

.imageWrapper
    position: relative
    width: 240px
    height: 240px
    +m
        width: 110px
        height: 110px
    & > img
        position: absolute
        left: 0
        width: 100%
        height: 100%
        border-radius: 10%
        object-fit: cover

.dataWrapper
    +m
        margin-left: -10px

.title
    +body-1
    max-height: 95px
    margin-bottom: 20px
    overflow: hidden
    color: $neutrals4
    +m
        max-height: 40px
        +body-3
    +dark
        color: $neutrals6

.catalogueType
    text-transform: uppercase
    +body-1
    +m
        +body-3
    +dark
        color: $neutrals8

.announcement
    margin-left: 5px
    text-transform: uppercase
    +body-bold-1
    +m
        +body-bold-3
    // +dark
    //     color: $neutrals8

.announcementInProgress
    color: $rarity-epic

.announcementComingSoon
    color: $rarity-elite

.announcementNewRelease
    color: $rarity-legendary

.icon
    float: left
    img
        width: 35px
        margin-top: -10px
        margin-bottom: 10px
        +m
            width: 16px

.creatorWrapper
    margin-top: 5px

.iconCreator
    img
        border-radius: 50%

.userLabel
    +hairline-1
    float: left
    margin-left: 10px
    color: $neutrals4
    +dark
        color: $neutrals6
    +m
        margin-top: -7px
        +hairline-2

.priceWrapper
    color: $neutrals4
    +dark
        color: $neutrals6
    +m
        margin-top: -5px
    span
        +hairline-2
        font-size: 20px
        margin-left: 5px
        +m
            +hairline-2

.price-free
    color: $green !important