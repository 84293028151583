@import styles/helpers

.content
    padding: 10px 80px 20px 80px
    +m
        padding: 5px 10px 20px 10px

.titleWrapper
    +body-1
    font-size: 30px
    +t
        text-align: center
    +dark
        color: $neutrals8

.imageWrapper
    margin-top: 10px
    position: relative
    width: 300px
    height: 300px
    & > img
        position: absolute
        left: 0
        width: 100%
        height: 100%
        border-radius: 10%
        object-fit: cover

.imageCompleteWrapper
    width: 300px
    height: 300px
    & > img
        width: 100%
        height: 100%

.detailWrapper
    margin-top: 20px
    +t
        margin-top: 30px
    +m
        padding-left: 20px
        padding-right: 20px

.icon
    float: left
    img
        width: 24px

.iconCreator
    img
        border-radius: 50%

.priceWrapper
    margin-top: 10px
    color: $neutrals4
    +t
        margin-top: 20px
    +dark
        color: $neutrals6
    span
        +body-1

.price-free
    +hairline-2
    color: $green !important

.descriptionWrapper
    +body-2
    font-size: 18px
    margin-bottom: 10px
    color: $neutrals4
    +dark
        color: $neutrals6
    +t
        text-align: center

.sponsorWrapper
    +t
        text-align: center

.labels
    float: left
    margin-left: 5px
    font-size: 16px
    color: $neutrals4
    +dark
        color: $neutrals6
    span
        +hairline-2
        font-size: 16px
        color: $neutrals3
        +dark
            color: $neutrals8

.eventRewardsTitle
    +hairline-1
    color: $neutrals4
    margin-top: 50px
    margin-bottom: 10px
    font-size: 20px
    text-align: center
    +dark
        color: $neutrals6
    +t
        margin-top: 20px
    +m
        padding-left: 20px
        padding-right: 20px

.sectionTitle
    +hairline-1
    font-size: 28px
    color: $neutrals4
    margin-top: 30px
    +d
        font-size: 24px
    +t
        font-size: 20px
    text-align: center
    +dark
        color: $neutrals6

.listItem
    color: $neutrals3
    +dark
        color: $neutrals8

.listItemTitle
    +body-2
    font-size: 20px
    margin-top: 3px
    +t
        font-size: 16px
    +m
        font-size: 14px
    +dark
        color: $neutrals6

.footer
    text-align: center
    margin-top: 20px

.markdownContent
    font-size: 24px
    margin-top: 20px
    margin-bottom: 20px
    background-color: $neutrals7
    color: $neutrals3
    +dark
        color: $neutrals8
    +d
        font-size: 20px
    +t
        font-size: 16px
    +dark
        background-color: $neutrals2
    h1,h2,h3,h4,h5
        +dark
            color: $neutrals6
    ol,ul
        list-style-type: inherit

.playerWrapper
    margin-top: 20px
    margin-bottom: 20px
    div
        height: 200px !important

.btnWrapper
    margin-top: 20px

.btnClaim
    background-color: $green

.btnRequirement
    background-color: $red
    margin-top: 5px
    height: 30px
    span
        margin-left: -10px

.modalOuter
    max-width: 600px !important
    +t
        max-width: 800px