@import styles/helpers

.container
    padding: 0px

.card
    padding: 15px 15px
    height: 65vh
    +m
        padding: 5px 5px
        height: 85vh
        background: none
        
.cardHeader
    margin: 1px 5px
    padding: 10px
    height: 7%
    text-align: center
    align-content: center
    background-color: $neutrals6
    border-radius: 10px 10px 0px 0px
    +dark
        background-color: $neutrals2

.cardContent
    height: 85%

.cardFooter
    margin: 1px 5px
    height: 8%
    text-align: center
    align-content: center
    background-color: $neutrals6
    border-radius: 0px 0px 10px 10px
    +dark
        background-color: $neutrals2
    
.carouselWrapper
    height: 100%
    & > div
        height: 100%

.carousel
    height: 100%
    margin-left: 5px
    margin-right: 5px
    padding: 5px
    color: $neutrals4
    background-color: $neutrals7
    +body-1
    +m
        font-size: 18px
        line-height: 1.5em
    & > div
        height: 100% !important
        & > div
            height: 100% !important
    +dark
        background-color: #000000

.dots
    li
        background-color: $neutrals5
        +dark
            background-color: $neutrals1
        margin: 0 !important
        padding: 2px !important

.next
    +t
        display: none
    +m
        display: none

.prev
    +t
        display: none
    +m
        display: none

.buttonClaim
    margin: 5px 0px 5px 0px
    background-color: $purple
    color: #FCFCFD

.buttonDisabled
    margin: 5px 0px 5px 0px
    background: $neutrals4
    color: #FCFCFD
    &:hover
        background: $neutrals4
        color: #FCFCFD

.btnNextLesson
    background-color: $purple
    color: #FCFCFD