@import styles/helpers

.title
    +body-1
    margin-top: 5px
    margin-bottom: 20px

.icon
    width: 100%
    border-radius: 10px

.subTitle
    margin-top: 20px
    color: $neutrals4
    text-align: center
    +body-1
    font-size: 20px

.levelTitle
    margin-top: 10px
    color: $neutrals4
    text-align: center
    +body-1

.content
    margin-top: 10px

.listItemTitle
    +caption-1
    font-size: 16px
    color: $neutrals2
    +dark
        color: $neutrals6
    +t
        font-size: 14px

.listItemDescription
    +body-3
    font-size: 16px
    margin-top: -5px
    color: $neutrals4
    +t
        font-size: 14px

.footerTitle
    margin-top: 30px
    color: $neutrals4
    text-align: center
    +body-2

.btns
    margin-top: 30px

.button
    width: 100%
    margin-bottom: 8px

.divider
    +dark
        border-top-color: $neutrals3 !important
    span
        font-size: 14px
        +m
            font-size: 12px
        +dark
            color: $neutrals5

.awardDetail
    margin-top: -10px

.awardLabel
    +body-3
    font-size: 14px
    color: $neutrals4
    margin-top: 7px
    +m
        font-size: 12px
    
.itemDetail
    padding: 1px 0