@import styles/helpers

.top
    +t
        text-align: center

.title
    margin-bottom: 20px
    font-size: 16pt
    +dark
        color: $neutrals8

.info
    +body-3
    color: $neutrals4
    margin-bottom: 20px
    
.dropdownWrapper
    display: none !important
    +t
        display: block !important

.row
    margin-top: 15px

.levelColumn
    margin-top: 5px
    +t
        display: none

.link
    +body-2
    color: $neutrals4
    padding-left: 5px
    margin-bottom: 10px
    cursor: pointer
    transition: color .2s
    &:hover,
    &.active
        color: $neutrals2
        +dark
            color: $neutrals8

.divider
    border: 1px solid $neutrals6
    +dark
        border: 1px solid $neutrals2

.button
    cursor: pointer
    height: 30px
    padding: 0 10px
    margin-top: 20px