@import styles/helpers

.content
    padding: 10px 80px 20px 80px
    +m
        padding: 5px 10px 20px 10px

.titleWrapper
    +body-1
    text-align: center
    +dark
        color: $neutrals8

.imageWrapper
    margin-top: 10px
    position: relative
    width: 300px
    height: 300px
    & > img
        position: absolute
        left: 0
        width: 100%
        height: 100%
        border-radius: 10%
        object-fit: cover

.detailWrapper
    margin-top: 20px
    +t
        margin-top: 30px
    +m
        padding-left: 20px
        padding-right: 20px

.icon
    float: left
    margin-top: -2px
    img
        width: 24px

.labels
    float: left
    margin-left: 5px
    font-size: 16px
    color: $neutrals4
    +dark
        color: $neutrals6
    span
        +hairline-2
        font-size: 16px
        color: $neutrals3
        +dark
            color: $neutrals8

.iconCreator
    img
        border-radius: 50%

.priceWrapper
    margin-top: 10px
    color: $neutrals4
    +t
        margin-top: 20px
    +dark
        color: $neutrals6
    span
        +body-1

.price-free
    +hairline-2
    color: $green !important

.descriptionWrapper
    +body-2
    margin-top: 10px
    font-size: 18px
    color: $neutrals4
    +t
        margin-top: 20px
    +dark
        color: $neutrals6

.courseBreakdown
    +hairline-1
    color: $neutrals4
    margin-top: 50px
    margin-bottom: 10px
    font-size: 18px
    text-align: center
    +dark
        color: $neutrals6
    +t
        margin-top: 20px
    +m
        padding-left: 20px
        padding-right: 20px

.listItem
    +body-2
    margin-left: 15px
    color: $neutrals3
    +dark
        color: $neutrals8

.footer
    text-align: center
    margin-top: 20px