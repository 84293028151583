@import styles/helpers

.head
    position: relative
    height: 326px
    padding: 32px 0
    background-repeat: no-repeat
    background-position: 50% 50%
    background-size: cover
    overflow: hidden
    +t
        height: 260px
        padding-bottom: 64px
    +m
        height: 230px
        padding-bottom: 56px

.body
    position: relative
    padding: 20px 0