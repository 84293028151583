@import styles/helpers

.title
    +body-1
    +dark
        color: #FCFCFD

.content
    +body-2
    color: $neutrals4
    margin-bottom: 30px

.button
    text-align: center
    & > button
        width: 100px