@import styles/helpers

.top
    background-color: $neutrals7
    align-items: center
    margin-bottom: 20px
    padding-top: 10px
    padding-bottom: 20px
    border-bottom: 1px solid $neutrals6
    +t
        display: block
        margin-bottom: 10px
    +m
        margin-bottom: 10px
    +dark
        background-color: $neutrals2
        border-color: $neutrals3

.title
    padding: 0 10px 0 10px
    +body-1
    +m
    +t
        +caption-1

.search
    position: relative
    flex-shrink: 0
    width: 320px
    margin-left: 30px
    +t
        width: 100%
        margin: 0

.input
    width: 100%
    height: 48px
    padding: 0 48px 0 14px
    border-radius: 12px
    background: none
    border: 2px solid $neutrals6
    +poppins
    +caption-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    +placeholder
        color: $neutrals4
    &:focus
        border-color: $neutrals4

.result
    position: absolute
    top: 8px
    right: 8px
    bottom: 8px
    width: 32px
    height: 32px
    border-radius: 50%
    background: $blue
    transition: background .2s
    svg
        fill: $neutrals8
    &:hover
        background: darken($blue, 10)
    
.nav
    display: flex
    +m
        margin: 0 -32px
        overflow: auto
        overflow-x: auto 
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none 
        &:before,
        &:after
            content: ""
            flex-shrink: 0
            width: 32px
            height: 1px
    
.link
    flex-shrink: 0
    margin: 0 6px
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2
    &:not(:last-child)
        +m
            margin-right: 12px

.row
    display: flex
    +t
        display: block

.wrapper
    flex-grow: 1
    padding-left: 32px
    +t
        padding-left: 0

.filters
    flex-shrink: 0
    width: 256px
    +t
        width: 100%
        margin-bottom: 32px

.range
    margin-bottom: 24px

.scale
    display: flex
    justify-content: space-between
    font-weight: 500

.group
    margin-bottom: 24px
    padding: 24px 0
    border-width: 1px 0
    border-style: solid
    border-color: $neutrals6
    +dark
        border-color: $neutrals3

.item
    &:not(:last-child)
        margin-bottom: 24px

.label
    padding: 0 10px 10px 10px
    color: $neutrals5
    +body-1
    +m
    +t
        +hairline-2

.addedCards
    float: left

.addedCardImage
    width: 20px
    border-radius: 20%
    margin-right: 10px
    margin-top: 7px
    +m
    +t
        margin-right: 5px
        margin-top: -10px

.reset
    display: inline-flex
    align-items: center
    +button-2
    cursor: pointer
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals2
        transition: fill .2s
        +dark
            fill: $neutrals8
    &:hover
        color: $blue
        svg
            fill: $blue

.list
    display: flex
    flex-wrap: wrap
    margin: -32px -16px 0
    +m
        display: block
        margin: 0

.btns
    margin-top: 32px
    text-align: center

.card
    flex: 0 0 calc(33.333% - 32px)
    max-width: calc(33.333% - 32px)
    margin: 32px 16px 0
    padding: 12px 
    box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12)
    border-radius: 20px
    +r(1279)
        flex: 0 0 calc(50% - 32px)
        max-width: calc(50% - 32px)
    +m
        max-width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px
    +dark
        background: $neutrals2

.buttonWrapper
    text-align: center
    margin-top: 20px
    +t
    +m
        margin-top: 10px

.button
    cursor: pointer
    img
        width: 30px
        margin-left: -15px
        margin-right: 5px