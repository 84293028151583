@import styles/helpers

.user
    margin: -300px 20px 0
    padding: 15px 15px
    border-radius: 15px
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12)
    background: $neutrals8
    border: 1px solid $neutrals6
    +t
        margin-top: -245px 
    +m
        margin-top: -220px
    +dark
        background: $neutrals2
        border-color: $neutrals3

.avatar
    width: 160px
    height: 160px
    margin-bottom: 15px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.name
    text-align: left
    margin-bottom: 4px
    +body-bold-1
    +m
        text-align: center

.code
    display: inline-flex
    align-items: center
    margin-bottom: 20px

.number
    +button-2
    color: $neutrals2
    +dark
        color: $neutrals8

.copy
    margin-left: 8px
    svg
        fill: $blue
        transition: fill .2s
    &:hover
        svg
            fill: darken($blue, 10)

.info
    margin-bottom: 20px
    +caption-2
    color: $neutrals4

.site
    display: inline-flex
    align-items: center
    margin-bottom: 48px
    +button-2
    color: $neutrals2
    +dark
        color: $neutrals8
    svg
        margin-right: 8px
        fill: $neutrals4

.control
    display: inline-block
    position: relative
    margin-bottom: 48px

.box
    position: absolute
    left: 50%
    bottom: calc(100% + 8px)
    z-index: 2
    width: 220px
    transform: translateX(-50%)
    padding: 32px 16px
    background: $neutrals8
    border: 1px solid $neutrals6
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12)
    border-radius: 16px
    text-align: center
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: $neutrals2
        border-color: $neutrals3
    &.active
        visibility: visible
        opacity: 1

.stage
    margin-bottom: 24px
    font-weight: 500

.share
    display: flex
    justify-content: center

.direction
    span
        display: flex
        justify-content: center
        align-items: center
        flex-shrink: 0
        width: 48px
        height: 48px
        border-radius: 50%
        background: $neutrals6
        transition: all .2s
        svg
            transition: fill .2s
        &:hover
            background: $blue
            svg
                fill: $neutrals8
        +dark
            background: $neutrals3
            svg
                fill: $neutrals8
            &:hover
                background: $blue
                svg
                    fill: $neutrals8
    &:not(:last-child)
        margin-right: 24px

.btns
    margin-top: 30px
    text-align: center

.button
    &.active
        background: $blue
        box-shadow: inset 0 0 0 2px $blue
        +dark
            background: $blue
        svg
            fill: $neutrals8
        span
            &:first-child
                display: none
            &:nth-child(2)
                display: inline
    &:not(:last-child)
        margin-right: 8px

.socials
    display: flex
    justify-content: center

.social
    svg
        fill: $neutrals4
        transition: fill .2s
    &:hover
        svg
            fill: $blue
    &:not(:last-child)
        margin-right: 24px
    
.note
    margin-top: 10px
    padding-top: 20px
    border-top: 1px solid $neutrals6
    +caption-2
    color: $neutrals4
    +dark
        border-color: $neutrals3

.wrapper
    text-align: left
    +m
        text-align: center

.wrap
    border-radius: 15px

.imgIcon
    margin-right: 10px
    width: 30px
    height: 30px

.line
    img
        margin-right: 10px
        width: 30px
        height: 30px
    
.text
    color: $neutrals4

.labelValue
    +body-2
    color: $neutrals2
    +dark
        color: $neutrals6

.divider
    border-top-color: $neutrals6 !important
    +dark
        border-top-color: $neutrals3
    span
        color: $neutrals2
        +body-2
        +dark
            color: $neutrals6

.achievementWrapper
    padding: 10px

.achievement
    background-color: $neutrals7
    border-radius: 10px
    padding: 5px
    +dark
        background-color: $neutrals2
    img
        border-radius: 10px
        width: 100%
        height: 100%
    video
        border-radius: 10px
        width: 100%
        height: 100%

.title
    color: $neutrals3
    +body-2
    +dark
        color: $neutrals6

.metaMaskWrapper
    margin-top: 20px
    text-align: center

.code
    margin-bottom: 10px

.number
    font-weight: 500
    color: $neutrals4

.copy
    margin-left: 8px
    svg
        fill: $blue
        transition: fill .2s
    &:hover
        svg
            fill: darken($blue, 10)