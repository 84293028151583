@import styles/helpers

.section
    overflow: hidden
    padding: 20px 0 30px
    // padding-bottom: 140px
    +x
        padding: 20px 0
        // padding-bottom: 112px
    +m
        padding: 20px 0
        // padding-bottom: 10px

.top
    display: flex
    align-items: center
    margin-bottom: 20px
    +m
        display: block

.title
    margin-top: -10px
    margin-bottom: 10px
    +body-1

.stage
    +body-bold-1
    color: $neutrals4

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.field
    .dropdown
        min-width: 256px
        +m
            min-width: 100%

.box
    margin-right: auto
    +m
        margin: 0 0 16px
    .dropdown
        min-width: auto
        margin-left: -24px
        +m
            display: inline-block
    
.wrapper
    margin: 0 -16px
    +m
        margin: 0 -4px

.item
    padding: 24px
    border-radius: 16px
    background: $neutrals8
    transition: box-shadow .2s
    +dark
        background: $neutrals1
    &:hover
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)
        .avatar
            width: 80px
            +d
                width: 64px

.head
    display: flex
    align-items: center
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $neutrals6
    +dark
        border-color: $neutrals3

.icon
    margin-right: 4px
    img
        width: 16px
    
.control
    display: flex

.button
    svg
        fill: $neutrals5
        transition: fill .2s
    &:hover
        svg
            fill: $blue
    &:not(:last-child)
        margin-right: 8px
    
.body
    text-align: center
    
.avatar
    position: relative
    width: 64px
    margin: 0 auto 16px
    transition: width .2s
    & > img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%
    &:before
        content: ""
        display: block
        padding-bottom: 100%
    
.nft
    position: absolute
    right: -4px
    bottom: -4px

.name
    margin-bottom: 2px
    font-weight: 500

.effects
    +caption-2
    color: $neutrals4
    span
        font-weight: 600
        color: $neutrals2
        +dark
            color: $neutrals6

.actionPoints
    display: flex
    align-items: center
    height: 24px
    margin-right: auto
    padding: 0 8px
    border-radius: 12px
    +caption-bold-2
    color: $neutrals8

.rarity-normal
    background-color: $rarity-normal

.rarity-advanced
    background-color: $rarity-advanced

.rarity-elite
    background-color: $rarity-elite

.rarity-epic
    background-color: $rarity-epic

.rarity-legendary
    background-color: $rarity-legendary

.card
    padding: 5px
    border-radius: 10px