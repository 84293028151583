@import styles/helpers

.section
    position: relative
    display: flex
    border-top: 1px solid $neutrals6
    padding-top: 10px
    +d
        min-height: 576px
    +m
        display: block
        min-height: auto
        padding: 15px 0 0 0
        text-align: center
    +dark
        border-color: $neutrals3

.container
    max-width: initial
    padding-left: 0px
    padding-right: 0px

.wrap
    position: relative
    z-index: 3

.stage
    margin-top: 25px
    margin-bottom: 25px
    +hairline-1
    color: $neutrals4

.title
    margin-bottom: 20px
    +m
        font-size: 48px

.text
    margin-bottom: 40px
    +body-2
    color: $neutrals4

.btns
    display: flex
    margin-bottom: 10px
    +m
        display: block

.button
    width: 100%
    margin-bottom: 10px
    cursor: pointer
    +m
        width: 100%
    &:not(:last-child)
        margin-right: 16px
        +m
            margin: 0 0 16px

.buttonDisabled
    background: $neutrals4
    &:hover
        background: $neutrals4

.gallery
    position: absolute
    top: 45%
    right: calc(50% - 600px)
    width: 684px
    transform: translateY(-50%)
    pointer-events: none
    +x
        right: calc(50% - 520px)
        width: 550px
    +d
        right: calc(50% - 440px)
        width: 400px
    +m
        position: static
        transform: translateY(0)
        display: none
    +s
        width: auto
        margin: 0 0 0 -22px

.preview
    &:first-child
        position: relative
        z-index: 2
    &:nth-child(2)
        right: -11%
        bottom: -3.5%
        z-index: 3
        width: 47%
    &:not(:first-child)
        position: absolute
    img
        width: 100%