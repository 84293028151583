@import styles/helpers

.wrapper
    padding: 20px 20px 10px 20px

.logoWrapper
    img
        width: 100%

.titleWrapper
    margin-top: 20px
    text-align: center
    h1
        +dark
            color: #FCFCFD
        +m
            font-size: 45px

.button
    margin-bottom: 10px
    width: 100%
    img
        width: 30px
        margin-left: -15px
        margin-right: 5px