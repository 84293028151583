/* #countdown .timer-wrapper {
  display: flex;
  justify-content: center;
} */

#countdown .timer {
  /* font-family: 'Montserrat'; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#countdown .text {
  color: #aaa;
}

#countdown .value {
  font-size: 40px;
}

/* .center-both {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */
