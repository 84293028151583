.message-prompt
    .ant-message-notice-content
        border-radius: 10px
        background: $neutrals6

.ant-list-split
    .ant-list-header
        border-bottom: 1px solid $neutrals6 !important
        +dark
            border-color: $neutrals3 !important

.ant-list-split
    .ant-list-item
        border-bottom: 1px solid $neutrals6 !important
        +dark
            border-color: $neutrals3 !important

.ant-list-item
    margin-left: 5px
    color: $neutrals4 !important
    +dark
        color: $neutrals6 !important