@import styles/helpers

.item
    +dark
        border-color: $neutrals3
    &.active
        .head
            &:before
                transform: translateY(-50%) rotate(180deg)
        .body
            display: block

.claimed
    fill: $green
    svg
        height: 100%

.head
    +body-2
    color: $neutrals2
    +dark
        color: $neutrals6
    margin: 5px 0 5px 0

.body
    +body-3
    color: $neutrals4
    margin-bottom: 10px

.iconWrapper
    display: flex !important
    align-items: center
    span
        svg
            color: $neutrals5
            +dark
                color: $neutrals3