@import styles/helpers

.card
    background-color: $neutrals6 !important
    padding-top: 10px
    padding-bottom: 10px
    border-bottom: 1px solid $neutrals6
    +dark
        background-color: $neutrals2 !important
        border-color: $neutrals3

.answerColumn
    background-color: #FCFCFD
    margin-right: 10px
    margin-bottom: 10px
    +dark
        border: 1px solid #FCFCFD !important
        background-color: #000000

.cardAnswer
    cursor: pointer
    background-color: #FCFCFD !important
    border: 0px !important
    +dark
        background-color: #000000 !important

.title
    margin-bottom: -5px
    white-space: initial
    +dark
        color: #FCFCFD

.answer
    background-color: #FCFCFD
    margin-top: -10px
    margin-bottom: -10px
    +dark
        background-color: #000000
        color: #FCFCFD

.displayResult
    div
        &:nth-child(2)
            +dark
                color: #FCFCFD