@import styles/helpers

.container
    padding: 20px
    +t
        padding: 5px

.header
    padding: 5px
    border-radius: 10px 10px 0px 0px
    background: $neutrals6
    border: 1px solid $neutrals6
    +dark
        background: $neutrals2
        border-color: $neutrals3

.tag
    margin: 2px
    padding: 0 2px !important
    border-radius: 5px !important

.tagEnd
    text-align: end

.title
    font-weight: 800
    font-size: 18px
    line-height: 1.25
    letter-spacing: -0.01em
    text-align: center
    color: $neutrals3
    margin-bottom: auto
    +dark
        color: $neutrals6

.playerWrapper
    position: relative
    padding-top: 56.25%
    div
        div
            iframe
                position: absolute
                top: 0
                left: 0

.textWrapper
    height: 60vh
    padding: 5px
    overflow: auto

.footer
    border-radius: 0px 0px 10px 10px
    background: $neutrals6
    border: 1px solid $neutrals6
    +dark
        background: $neutrals2
        border-color: $neutrals3

.buttonClaim
    background-color: $purple
    color: #FCFCFD
    &:hover
        background-color: $purple
        color: #FCFCFD

.buttonDisabled
    background-color: $neutrals4
    color: #FCFCFD
    &:hover
        background-color: $neutrals4
        color: #FCFCFD

.btnNextLesson
    background-color: $purple
    color: #FCFCFD

.btnTab
    margin-right: 5px
    box-shadow: 0 0 0 0 !important

.antIcon
    svg
        margin-left: 0px !important
        margin-right: 3px !important

.footerBtnWrapper
    padding: 5px

.markdown
    +poppins
    font-size: 18px
    line-height: 1.5em
    height: 100%
    color: $neutrals4
    background: $white
    +dark
        background: $neutrals1
    +m
        font-size: 14px
    img
        +m
            width: 50%
    h1,h2,h3,h4,h5
        +dark
            color: $neutrals8
    code
        background-color: $neutrals6
        color: $neutrals2
        +dark
            background-color: #383B41
            color: $neutrals8