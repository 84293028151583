[class^="section"]
    padding: 20px 0
    +x
        padding: 20px 0
    +m
        padding: 20px 0

.section-bg
    background: $neutrals7 
    +dark-common
        background: $neutrals2

.section-pb
    padding-top: 0
    +d
        padding-top: 0
    +m
        padding-top: 0

.section-pt20
    padding-top: 20px
    +d
        padding-top: 20px
    +m
        padding-top: 10px

.section-pt80
    padding-top: 80px
    +d
        padding-top: 64px
    +m
        padding-top: 32px