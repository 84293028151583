@import styles/helpers

.content
    padding: 10px
    height: 100%

.image
    width: 100%

.title
    margin-top: 20px !important
    text-align: center
    +dark
        color: $neutrals8

.subTitle
    text-align: center
    color: $neutrals4
    +hairline-1

.list1
    margin-left: 20px
    font-size: 14pt
    li
        list-style-type: disc

.list2
    margin-top: -20px
    margin-left: 20px
    font-size: 14pt
    li
        list-style-type: disc
        margin-top: 20px

.field
    width: 100%
    margin-top: 20px
    margin-bottom: 20px