@import styles/helpers

.section
    overflow: hidden
    padding-top: 10px
    height: 270px
    +m
        height: 210px

.wrapper
    margin: 0 -16px
    +m

.stage
    +hairline-1
    margin-left: 25px
    margin-bottom: 5px
    text-align: left
    color: $neutrals4
    +dark
        color: $neutrals7
    +m
        margin-left: 5px
        +hairline-2

.item
    width: 180px !important
    height: 200px
    padding: 10px
    border-radius: 16px
    background: $neutrals8
    transition: box-shadow .2s
    cursor: pointer
    +dark
        background: $neutrals1
    +m
        width: 140px !important
        height: 160px

.head
    display: flex
    align-items: center
    justify-content: center
    margin-top: 10px
    +dark
        border-color: $neutrals3

.icon
    width: 16px

.source
    margin-left: 5px
    color: $neutrals4
    +caption-2

.body
    text-align: center
    
.imgWrapper
    position: relative
    width: 100px
    margin: -5px auto
    transition: width .2s
    +m
        width: 80px
    & > img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 15%
    &:before
        content: ""
        display: block
        padding-bottom: 100%

.title
    +caption-2
    margin-top: 10px
    max-height: 57px
    overflow: hidden
    color: $neutrals4
    +m
        max-height: 37px
    +dark
        color: $neutrals7